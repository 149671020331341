import { Component, Prop, Watch } from 'vue-property-decorator';
import $ from 'jquery';
import PlayerApi from '@/support/playerApi';
import StudioPageBase from '@/support/studioPageBase';
import Util from '@/support/utility';
import { IWizardManager } from '@/interfaces';
import { EventBus } from '@/support/eventBus';

declare var AzureStorage: any;

interface IUploadingVideo {
    study: string;
    name: string;
    title: string;
    streamPath: string;
    file: any;
    fileName: string;
    size: string;
    state: string;
    uploaded: boolean;
    encoding: boolean;
    busy: boolean;
    canEdit: boolean;
    className: string;
    encodeResult: SUR.BackgroundJobDto;
    progress: number;
    progressClassName: string;
    startTime: any;
    duration: string;
    interval: any;
}

let NewUploadingVideo: IUploadingVideo = {
    study: null,
    name: null,
    title: null,
    streamPath: null,
    file: null,
    fileName: null,
    size: null,
    state: 'Pending',
    uploaded: false,
    encoding: false,
    busy: false,
    canEdit: true,
    className: null,
    encodeResult: null,
    progress: 0,
    progressClassName: 'progress-bar-warning',
    startTime: null,
    duration: null,
    interval: 0,
};

@Component
export default class UploadVideosComponent extends StudioPageBase {
    options = {
    };

    study: string = null;
    name: string = null;
    uploads: IUploadingVideo[] = [];
    doneDisabled: boolean = false;
    uploadDisabled: boolean = true;
    uploading: boolean = false;
    allEncoding: boolean = false;
    browseDisabled = true;
    finished = false;
    errors: string[] = [];
    updateDurationsInterval: any = null;

    @Prop({ default: null })
    wizard: IWizardManager;

    @Prop({ default: <any>{} })
    parent: any;

    @Watch('study', { immediate: true })
    onStudyChanged(val: string, oldVal: string) {
        this.updateButtons();

        this.uploads.forEach(upload => {
            if (upload.state == 'Pending')
                upload.study = this.study;
        });

    }

    @Watch('name', { immediate: true })
    onNameChanged(val: string, oldVal: string) {
        this.updateButtons();
    }

    get showDone() {
        return this.finished || (this.uploads.length && this.allEncoding);
    }

    created() {
        Debug.setDebugModule('UploadVideos', this);
        if (this.serverData.isDeveloper) {
            this.study = 'Test';
            //this.name = 'Test' + Util.getRandomInt(1000);
        }

        super.created('uploadVideos', true);
    }

    mounted() {
        super.mounted();
        this.parent = this.wizard.parent;
        this.loadPreviousJobs();
    }

    beforeDestroy() {
        this.uploads.forEach(upload => {
            if (upload.interval) {
                clearInterval(upload.interval);
                upload.interval = 0;
            }
        });
    }

    closeWithCheck() {
        let anyPending = false;

        this.uploads.forEach(upload => {
            if (upload.state == 'Pending') {
                anyPending = true;
                return;
            }
        });

        if (anyPending) {
            (this.$modal).show('leave-page');
        } else {
            this.close();
        }
    }

    async loadPreviousJobs() {
        try {
            let backgroundJobs = await PlayerApi.uploadVideosList(this.studio.studio);

            Debug.log('uploadVideos load', this.studio.studio, backgroundJobs.length);

            backgroundJobs.forEach(job => {
                let upload = Object.assign({}, NewUploadingVideo);
                upload.study = job.study;
                upload.title = job.display;
                upload.fileName = job.fileName;
                upload.busy = true;
                upload.canEdit = false;
                upload.progress = Math.round(25.0 + (job.progress * 0.75));
                upload.encoding = true;
                upload.encodeResult = job;
                upload.progressClassName = 'progress-bar-success';
                this.uploads.push(upload);

                this.completedUpload(upload, true);
                if (job.completed)
                    this.checkUploadProgress(upload, false);
                else
                    this.monitorUploadProgress(upload);
            });

        } catch (err) {
            let message = err.message || 'ERROR';
            Debug.error('load failed', message);
            return false;
        } finally {
            this.updateButtons();
        }
    }

    done() {
        this.closeWithCheck();
    }

    cancel() {
        this.closeWithCheck();
    }

    close() {
        this.wizard.back();
    }

    closeDialog() {
        (<any>this.$modal).hide('leave-page');

        this.close();
    }

    cancelCloseDialog() {
        (<any>this.$modal).hide('leave-page');
    }

    /*
file Info: {
  File(594568115)
 lastModified: 1523654215888
 lastModifiedDate:Fri Apr 13 2018 14:16:55 GMT-0700 (Pacific Daylight Time) {}
 name:"4KVideo.mp4"
 size:594568115
 type:"video/mp4"
 webkitRelativePath:"videos/4KVideo.mp4"
}
  */
    onFilesSelected(evt) {
        if (!evt.target.files || !evt.target.files.length || !evt.target.files[0] || !evt.target.files[0].name)
            return;

        let fileName = evt.target.files.length == 1 ? this.name : null;
        this.name = null;

        let uploads: IUploadingVideo[] = [];

        let files = evt.target.files;
        for (let idx = 0; idx < files.length; idx++) {
            let file = files[idx];

            if (!file.type.startsWith('video/'))
                continue;

            let name = fileName || file.name.replace(/\.[^/.]+$/, "");

            let upload = Object.assign({}, NewUploadingVideo);
            upload.study = this.study;
            upload.name = name;
            upload.title = name;
            upload.streamPath = this.studio.studio + '\\' + this.study + '\\' + name;
            upload.file = file;
            upload.size = Util.formatNumberWithCommas(file.size / 1024);
            upload.fileName = file.name;
            upload.className = 'state-in-progress';

            uploads.push(upload);
            this.uploads.push(upload);
            this.uploadDisabled = false;
        }

        let streams = [];
        uploads.forEach(upload => {
            let stream = {
                studio: this.studio.studio,
                study: upload.study,
                stream: upload.name,
                title: upload.name,
            };
            streams.push(stream);
        });

        this.getUploadTitles(uploads, streams);

        // clear files input
        let $files = $("#files");
        if ($files && $files[0])
            (<any>$files[0]).value = null;

        this.updateButtons();
    }

    addVideos() {
        if (this.validateInput()) {
            $("#files").click();
        }
    }

    addVideosFromFolder() {
        if (this.validateInput()) {
            $("#folder").click();
        }
    }

    startUploads() {
        this.uploadDisabled = true;

        this.uploads.forEach(upload => {
            if (upload.state != 'Pending') return;

            this.uploadFile(upload);
        });

        this.updateDurations();
    }

    removeUpload(upload: IUploadingVideo) {
        let idx = this.uploads.indexOf(upload);
        if (idx != -1) {
            this.uploads.splice(idx, 1);
        }

        this.updateButtons();
    }

    async getUploadTitles(uploads: IUploadingVideo[], streams) {

        try {
            let response = await PlayerApi.getUploadTitles(this.studio.studio, streams);

            for (let idx = 0; idx < uploads.length; idx++) {
                let upload = uploads[idx];
                let stream = response.streams[idx];
                if (stream.title)
                    upload.title = stream.title;
            }
        } catch (err) {
            let message = err.message || 'ERROR';
            Debug.error('getUploadTitles failed', streams, uploads, message);
            return false;
        } finally {

        }
    }

    async uploadFile(upload: IUploadingVideo) {
        Debug.log('uploadFile', upload.streamPath, upload.file);
        upload.state = 'Starting';
        upload.className = 'state-in-progress flashing-text';
        upload.canEdit = false;
        upload.busy = true;
        upload.startTime = new Date();
        this.updateButtons();

        try {
            let uploadInfo = await this.getUploadUrl(upload);
            if (uploadInfo) {
                let url = uploadInfo.url;

                let uploaded = await this.uploadToStorageBlob(upload, url);

                this.completedUpload(upload, uploaded);
                uploadInfo.uploaded = uploaded;

                let encodeVideoDto = await this.encodeUploadedVideo(uploadInfo);

                if (encodeVideoDto) {
                    Debug.log('uploadFile encoding', upload.streamPath, encodeVideoDto);

                    upload.encoding = true;
                    upload.encodeResult = encodeVideoDto;
                    upload.progressClassName = 'progress-bar-success';
                    this.updateButtons();
                    this.monitorUploadProgress(upload);
                    return true;
                }
            }

            this.completedUpload(upload, false);
            this.completedEncode(upload, 'Error');
            return false;

        } catch (err) {
            let message = err.message || 'ERROR';
            this.completedUpload(upload, false);
            this.completedEncode(upload, 'Error');
            return null;
        } finally {

        }
    }

    uploadToStorageBlob(upload: IUploadingVideo, url: string) {
        let parts = url.split('?');
        if (parts.length != 2) {
            Debug.error('uploadToStorageBlob invalid url', url);
            throw new Error('Invalid url');
        }

        let baseUrl = parts[0];
        let sas = parts[1];

        parts = baseUrl.split('/');
        if (parts.length < 4) {
            Debug.error('uploadToStorageBlob invalid url', baseUrl);
            throw new Error('Invalid url');
        }

        let accountUrl = parts[0] + '//' + parts[2];
        let container = parts[3];
        let name = parts[4] || upload.file.name;

        let blobService = AzureStorage.Blob.createBlobServiceWithSas(accountUrl, sas);
        if (!blobService) {
            Debug.error('uploadToStorageBlob createBlobServiceWithSas failed');
            throw new Error('Failed to create blobService');
        }

        return Util.createPromise<boolean>((resolve, reject) => {
            let file = upload.file;
            let customBlockSize = file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;

            blobService.singleBlobPutThresholdInBytes = customBlockSize;

            let monitor = blobService.createBlockBlobFromBrowserFile(container, name, file, { blockSize: customBlockSize }, (error, result, response) => {
                Debug.log('uploadToStorageBlob completed', upload.streamPath);

                if (error || !response || !response.isSuccessful) {
                    Debug.error('uploadToStorageBlob failed', error, response, upload.streamPath);
                    reject(false);
                    return;
                }

                resolve(true);
                return;
            });

            if (monitor) {
                monitor.on('progress', () => {
                    let progress = monitor.getCompletePercent();
                    if (progress) {
                        upload.progress = Math.round(Math.max(2, progress * 0.25));
                    }
                });
            }

        });
    }

    async getUploadUrl(upload: IUploadingVideo) {
        let streamPath = this.studio.studio + '\\' + upload.study + '\\' + upload.name;

        try {
            let response = await PlayerApi.getUploadUrl(this.studio.studio, upload.study, upload.name, upload.title);

            // Upload can be renamed here
            upload.name = response.title;
            upload.title = response.title;
            upload.streamPath = this.studio.studio + '\\' + upload.study + '\\' + upload.name;

            return response;
        } catch (err) {
            let message = err.message || 'ERROR';
            Util.showToast('Failed to get upload url for ' + upload.name, true);
            this.completedUpload(upload, false);
            return null;
        } finally {

        }
    }

    async encodeUploadedVideo(uploadInfo: SUR.UploadUrlDto) {
        let studio = uploadInfo.studio;
        let study = uploadInfo.study;
        let streamName = uploadInfo.stream;

        let streamPath = studio + '\\' + study + '\\' + streamName;

        try {
            let response = await PlayerApi.encodeUploadedVideo(uploadInfo);

            Debug.log("encodeUploadedVideo running", streamPath, response);

            return response;
        } catch (err) {
            let message = err.message || 'ERROR';
            Util.showToast('Failed to encode ' + streamName, true);
            return null;
        } finally {

        }
    }

    monitorUploadProgress(upload: IUploadingVideo) {

        upload.interval = setInterval(() => {
            this.checkUploadProgress(upload);
        }, 10000);
    }

    async checkUploadProgress(upload: IUploadingVideo, showToast = true) {
        try {

            let result = await PlayerApi.checkBackgroundJob(upload.encodeResult);

            upload.encodeResult = result;

            if (result.completed) {
                if (result.succeeded) {
                    this.completedEncode(upload, 'Finished', showToast);
                    return true;
                }
                else {
                    this.completedEncode(upload, 'Error', showToast);
                    return false;
                }
            }

            // Still running
            upload.progress = Math.round(25.0 + (result.progress * 0.75));
            return false;
        } catch (err) {
            let message = err.message || 'ERROR';
            Debug.error('checkUploadProgress', message, upload);
            this.completedEncode(upload, 'Error');
            return false;
        }
    }

    completedEncode(upload, state, showToast = true) {
        if (upload.interval) {
            clearInterval(upload.interval);
            upload.interval = 0;
        }

        upload.busy = false;

        if (state == 'Error') {
            Debug.error('completedEncode failed', upload.streamPath, upload);
            upload.state = 'Error';
            upload.className = 'state-failed';
            if (showToast)
                Util.showToast('Failed to encode ' + upload.title, true);
        } else {
            Debug.log('completedEncode completed', upload.state, upload.streamPath, upload);
            upload.state = state;
            if (showToast)
                Util.showToast(upload.title + ' is ready');

            EventBus.Instance.$emit(EventBus.VideosChanged, null);
        }

        this.updateButtons();
    }

    completedUpload(upload, result) {

        if (!result) {
            Debug.error('completedUpload failed', upload.streamPath, upload);
            upload.state = 'Error';
            upload.className = 'state-failed';
            upload.busy = false;
            Util.showToast('Failed to upload ' + upload.title, true);
        } else {
            Debug.log('completedUpload completed', upload.state, upload.streamPath, upload);
            upload.state = 'Uploaded';
            upload.uploaded = true;
            upload.className = 'state-success';
        }

        this.updateButtons();
    }

    updateButtons() {
        this.browseDisabled = !this.isValidInput();

        let anyPending = false;
        let anyUploading = false;
        let anyNotEncoding = false;
        let countRunning = 0;
        let countEncoding = 0;
        let countFinished = 0;

        this.uploads.forEach(upload => {
            if (upload.state == 'Error' || upload.state == 'Finished') {
                countFinished++;
                return;
            }

            if (upload.state == 'Pending') {
                anyPending = true;
                return;
            }

            if (upload.busy)
                countRunning++;

            if (!upload.uploaded)
                anyUploading = true;
            else if (!upload.encoding)
                anyNotEncoding = true;
            else if (upload.encoding)
                countEncoding++;
        });

        this.doneDisabled = anyUploading || anyNotEncoding;
        this.uploadDisabled = !anyPending;
        this.allEncoding = countEncoding > 0 && countEncoding == countRunning;
        this.finished = this.uploads.length > 0 && countFinished == this.uploads.length;
        this.uploading = anyUploading;

        //console.log(this.doneDisabled, this.uploadDisabled, this.allEncoding);
        //console.log(anyPending, anyUploading, anyNotEncoding, countRunning, countEncoding);
    }

    isValidInput() {
        if (!this.study)
            return false;
        else if (this.study != Util.sanitizeName(this.study))
            return false;

        if (!this.name)
            return true;
        else if (this.name != Util.sanitizeName(this.name))
            return false;

        return true;
    }

    validateInput() {
        this.errors.splice(0, this.errors.length);

        if (!this.study)
            this.errors.push('Study required');
        else if (this.study != Util.sanitizeName(this.study))
            this.errors.push('Invalid characters in study.');

        // optional name
        if (this.name && this.name != Util.sanitizeName(this.name))
            this.errors.push('Invalid characters in name.');

        return !this.errors.length;
    }

    updateDurations() {
        if (this.updateDurationsInterval) return;

        this.updateDurationsInterval = setInterval(() => {
            let now = new Date();
            this.uploads.forEach(upload => {
                if (upload.startTime &&
                    upload.state != 'Pending' &&
                    upload.state != 'Error' &&
                    upload.state != 'Finished') {

                    upload.duration = Util.duration(now, upload.startTime);
                }

                if (this.finished) {
                    this.updateDurationsInterval = null;
                    clearInterval(this.updateDurationsInterval);
                }
            });
        }, 1000);
    }

    testDuration(end, start) {
        let duration = Util.duration(end, start);
        console.log(duration);
    }
}
